'use client';

import { pianoId } from './analyticsIds';

declare global {
	interface Window {
		pa: {
			setConfigurations: (config: Record<string, string>) => void;
			sendEvent: (
				name: string,
				options: Record<string, string | undefined>,
			) => void;
		};
	}
}

declare interface PianoServicePageOptions {
	name: string;
	page_chapter2?: string;
	page_chapter3?: string;
}

declare interface PianoServiceEventOptions {
	name: string;
	subject?: string;
	page: string;
	click_chapter2?: string;
	click_chapter3?: string;
}

export default class PianoService {
	private static _isBlocked = false;
	private static _isInitialized = false;

	private static _queue: Record<
		number,
		Array<PianoServicePageOptions | PianoServiceEventOptions>
	> = {
		1: [],
		2: [],
	};

	public static isAvailable(): boolean {
		return window.pa !== undefined;
	}

	public static async initialize(): Promise<void> {
		if (this._isInitialized) return;

		try {
			await this._initPiano();
		} catch (error) {
			this._isBlocked = true;
			console.warn('Failed to initialize Piano analytics', error);
		}

		this._isInitialized = true;
		this._runQueue();
	}

	private static _initPiano(): Promise<void> {
		let retries = 10;
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line prefer-const
			let interval: NodeJS.Timeout;
			const setup = () => {
				if (--retries <= 0) return reject();

				if (!window.pa)
					return console.log(
						`piano not available yet, retrying ${retries} times`,
					);

				clearInterval(interval);
				window.pa.setConfigurations({
					site: pianoId,
					collectDomain: 'https://atconnect.npo.nl',
				});
				console.log('Piano configured');
				resolve();
			};

			interval = setInterval(setup, 200);
		});
	}

	public static sendPage(options: PianoServicePageOptions): void {
		if (this._isBlocked) return;

		if (!this._isInitialized) return void this._queue[1].push(options);

		window.pa.sendEvent('page.display', {
			page: options.name,
			page_chapter1: 'inclusiefdesign',
			page_chapter2: options.page_chapter2,
			page_chapter3: options.page_chapter3,
			platform: 'site',
			broadcaster: 'ntr',
			c_nmo_02: '0',
		});
	}

	public static sendEvent({
		type,
		options,
	}: {
		type?:
			| 'click.action'
			| 'click.navigation'
			| 'click.download'
			| 'click.exit';
		options: PianoServiceEventOptions;
	}): void {
		if (this._isBlocked) return;

		if (!this._isInitialized) return void this._queue[2].push(options);

		window.pa.sendEvent(type ?? 'click.action', {
			click: options.name,
			click_chapter1: 'inclusiefdesign',
			click_chapter2: options.click_chapter2,
			click_chapter3: options.click_chapter3,
			platform: 'site',
		});
	}

	private static _runQueue(): void {
		if (this._isBlocked) return;

		this._queue[1].forEach((options) =>
			this.sendPage(options as PianoServicePageOptions),
		);
		this._queue[2].forEach((options) =>
			this.sendEvent({
				options: options as PianoServiceEventOptions,
			}),
		);
	}
}
