'use client';

import EmojiText from '@/components/EmojiText/EmojiText';
import { NavigationItemMetaData } from '@/components/Navigation/NavigationItem';
import { Menu } from '@headlessui/react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const MainNavigationItem = ({
	as,
	className,
	item,
	subItems,
}: {
	as: 'li' | 'div';
	className?: string;
	item: {
		url?: string;
		displayName: ReactNode;
		metadata?: Partial<NavigationItemMetaData>;
	};
	subItems?: {
		url: string;
		displayName: string;
		metadata?: Partial<NavigationItemMetaData>;
	}[];
}) => {
	const Component = as;
	const reduceMotion = useReducedMotion();
	const pathname = usePathname();

	const styles = twMerge(
		'shadow-border-bottom-sm flex gap-1 items-center rounded-full after:rounded-full bg-white dark:bg-primary-950 px-4 py-1.5 text-lg font-bold text-primary-700 dark:text-white transition-all active:opacity-95 hover:opacity-80',
		className,
	);

	const isActive =
		(!!item.url && pathname?.startsWith(item.url)) ||
		subItems?.some((subItem) => pathname?.startsWith(subItem.url));

	return (
		<Component className="relative">
			{!!item && !!item.url && !subItems && (
				<Link href={item.url} className={styles}>
					{item.displayName}
					{isActive && <MainNavigationItemIsActiveIndicator />}
				</Link>
			)}
			{!!subItems && !item.url && (
				<Menu>
					{({ open }) => (
						<>
							<Menu.Button className={styles}>
								{({ open }) => (
									<>
										{item.displayName}
										<Icon
											path={mdiChevronDown}
											size={0.75}
											className={`-me-1 ${open ? 'rotate-180' : 'rotate-0'} transition-all`}
										/>
										{isActive && (
											<MainNavigationItemIsActiveIndicator />
										)}
									</>
								)}
							</Menu.Button>
							<AnimatePresence>
								{open && (
									<motion.div
										className="absolute z-50 w-full origin-top-right max-md:right-12 md:left-1/2"
										initial={{
											height: 0,
											opacity: 0,
											translateX: '-50%',
											scaleY: reduceMotion ? 1 : 0.6,
										}}
										animate={{
											height: 'auto',
											opacity: 1,
											translateX: '-50%',
											scaleY: 1,
										}}
										exit={{
											height: 0,
											opacity: 0,
											translateX: '-50%',
											scaleY: reduceMotion ? 1 : 0.6,
										}}
									>
										<Menu.Items className="shadow-border-bottom-sm z-50 mt-2 w-64 rounded-xl bg-white/85 p-1 shadow-xl backdrop-blur-md after:rounded-xl dark:bg-primary-900/85">
											{subItems.map((subItem) => (
												<Menu.Item key={subItem.url}>
													<Link
														href={subItem?.url}
														className="shadow-border-bottom-sm flex gap-3 rounded-lg px-4 py-2 text-lg font-bold text-primary-800 transition-all after:rounded-lg after:opacity-0 active:opacity-95 ui-active:bg-primary-500 ui-active:text-white ui-active:after:opacity-100 dark:text-white"
													>
														{!!subItem?.metadata
															?.emoji && (
															<span aria-hidden>
																<EmojiText>
																	{
																		subItem
																			.metadata
																			.emoji
																	}
																</EmojiText>
															</span>
														)}
														{subItem.displayName}
													</Link>
												</Menu.Item>
											))}
										</Menu.Items>
									</motion.div>
								)}
							</AnimatePresence>
						</>
					)}
				</Menu>
			)}
		</Component>
	);
};

const MainNavigationItemIsActiveIndicator = () => (
	<div
		aria-label="Hier ben je nu"
		className="absolute bottom-1 left-1/2 h-0.5 w-8 -translate-x-1/2 rounded-full bg-primary-700 dark:bg-white"
	/>
);

export default MainNavigationItem;
